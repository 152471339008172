import React, { Component } from "react";
import "./../css/component/contact-section.css";
import { Trans, withTranslation } from "react-i18next";
import jsonp from "jsonp";
import { CiCircleCheck } from "react-icons/ci";

class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      axName: "",
      axEmail: "",
      axLastName: "",
      axBusinessName: "",
      axCohort: "",
      axMessage: "",
      loading: false,
      error: false,
      success: false,
    };
  }

  onSubmit = (e) => {
    this.setState({
      loading: true,
      error: false,
      success: false,
    });

    e.preventDefault();
    const url =
      "https://gmail.us11.list-manage.com/subscribe/post?u=c2288d4bacc129c1c97717956&amp;id=0349b8a037&amp;f_id=002ad7e3f0";
    jsonp(
      `${url}&FNAME=${this.state.axName}&LNAME=${this.state.axLastName}&BNAME=${this.state.axBusinessName}&MESSAGE=${this.state.axMessage}&COHORT=${this.state.axCohort}&EMAIL=${this.state.axEmail}`,
      { param: "c" },
      (_, data) => {
        const { result } = data;

        if (result === "error") {
          this.setState({
            loading: false,
            error: true,
          });
        } else {
          this.setState({
            loading: false,
            success: true,
          });
        }
      }
    );
  };

  render() {
    const { t } = this.props;

    return (
      <section id="contact" className="pt--40 pb--60 pt_lg--40 pb_lg--80">
        <div className="container">
          <div className="row justify-content-center pt--60">
            <div className="row justify-content-left justify-content-lg-start pt--50">
              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                <div className="contact-description col-11">
                  <p className="title">
                    <Trans i18nKey={"contact.title"} components={[<span />]} />
                  </p>
                  <p className="body">{t("contact.body")}</p>
                </div>
              </div>
              <div className="col-12 col-sm-11 col-md-11 col-lg-6 contact-card">
                <form
                  id="contact"
                  className="contact-form"
                  onSubmit={this.onSubmit}
                >
                  <div className="container">
                    {this.state.error && (
                      <div className="subscribe-error row mb--30 col-12">
                        {t("contact.form.errorMssg")}
                      </div>
                    )}
                    {this.state.success && (
                      <div
                        class="alert alert-success subscribe-success"
                        role="alert"
                      >
                        <CiCircleCheck />
                        <span>{t("contact.form.successMssg")}</span>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          name="name"
                          id="item01"
                          className="col-12"
                          value={this.state.axName}
                          required
                          onChange={(e) => {
                            this.setState({ axName: e.target.value });
                          }}
                          placeholder={t("contact.form.name")}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          name="lastName"
                          id="item02"
                          className="col-12"
                          value={this.state.axLastName}
                          required
                          onChange={(e) => {
                            this.setState({ axLastName: e.target.value });
                          }}
                          placeholder={t("contact.form.lastName")}
                        />
                      </div>
                    </div>
                    <div className="row mt--20">
                      <div className="col-12">
                        <input
                          type="text"
                          name="businessName"
                          id="item03"
                          className="col-12"
                          value={this.state.axBusinessName}
                          required
                          onChange={(e) => {
                            this.setState({ axBusinessName: e.target.value });
                          }}
                          placeholder={t("contact.form.businessName")}
                        />
                      </div>
                    </div>
                    <div className="row mt--20">
                      <div className="col-12">
                        <input
                          type="text"
                          name="email"
                          id="item04"
                          className="col-12"
                          value={this.state.axEmail}
                          required
                          pattern=".*\S+.*"
                          onChange={(e) => {
                            this.setState({ axEmail: e.target.value });
                          }}
                          placeholder={t("contact.form.email")}
                        />
                      </div>
                    </div>
                    <div className="row mt--20">
                      <div className="col-12">
                        <select
                          name="cohort"
                          id="item06"
                          className="col-12"
                          required
                          onChange={(e) => {
                            this.setState({ axCohort: e.target.value });
                          }}
                        >
                          <option value="" disabled selected hidden>
                            {t("contact.form.cohort.title")}
                          </option>
                          <option value="Investment">
                            {t("contact.form.cohort.investment")}
                          </option>
                          <option value="HR Consulting">
                            {t("contact.form.cohort.hrConsulting")}
                          </option>
                          <option value="HR Recruiting">
                            {t("contact.form.cohort.hrRecruiting")}
                          </option>
                          <option value="Business Consulting">
                            {t("contact.form.cohort.businessConsulting")}
                          </option>
                          <option value="Data-driven Management">
                            {t("contact.form.cohort.dataDrivenManagement")}
                          </option>
                          <option value="Other">
                            {t("contact.form.cohort.other")}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div className="row mt--20">
                      <div className="col-12">
                        <textarea
                          name="message"
                          id="item05"
                          className="col-12"
                          placeholder={t("contact.form.message")}
                          value={this.state.axMessage}
                          onChange={(e) => {
                            this.setState({ axMessage: e.target.value });
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mt--30">
                      <div className="col-4">
                        <button
                          className="submit-btn col-12"
                          type="submit"
                          value="submit"
                          name="submit"
                          id="mc-embedded-subscribe"
                          disabled={this.state.loading}
                          style={
                            this.state.loading
                              ? { cursor: "not-allowed", opacity: "0.6" }
                              : {}
                          }
                        >
                          {t("contact.form.btn")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(ContactSection);
