import React, { Component, Fragment, Suspense } from "react";
import CustomModal from "../component/customModal";
import EmbeddedPrototype from "../component/embeddedPrototype";
import { initAnalytics } from "../GAUtils";
import { Bars } from "react-loader-spinner";

class Tour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      axName: "",
      axEmail: "",
      axLastName: "",
      axBusinessName: "",
      axCohort: "",
      axMessage: "",
      loading: false,
      error: false,
      success: false,
      show: false,
    };
  }

  async componentDidMount() {
    initAnalytics("/tour");
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  handleSubmit = () => {
    const event = {
      name: "leave_tour",
      params: {
        category: "User",
        label: "tour",
      },
    };
    initAnalytics(null, event);

    const { history } = this.props;
    history.push("/");
  };

  render() {
    return (
      <Fragment>
        <Suspense
          fallback={
            <Bars
              height="40"
              width="40"
              color="#2B80F2"
              ariaLabel="bars-loading"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClass="mt--20"
              visible={true}
            />
          }
        >
          <CustomModal
            show={this.state.show}
            handleClose={this.handleClose}
            handleShow={this.handleShow}
            handleSubmit={this.handleSubmit}
          />

          <EmbeddedPrototype handleShow={this.handleShow} />
        </Suspense>
      </Fragment>
    );
  }
}

export default Tour;
