import { Trans, withTranslation } from "react-i18next";
import React, { Component } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import "./../css/component/embedded-prototype.css";
import jsonp from "jsonp";
import countryList from "react-select-country-list";
import Select from "react-select";
import { Bars } from "react-loader-spinner";
import { NavLink } from "react-router-dom";

class EmbeddedPrototype extends Component {
  constructor(props) {
    super(props);
    this.state = {
      axName: "",
      axEmail: "",
      axLastName: "",
      axBusinessName: "",
      axCountry: "",
      axRole: "",
      loading: false,
      error: false,
      success: false,
      show: false,
      loadedIFrame: false,
      width: window.innerWidth,
      countryOptions: null,
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ width: window.innerWidth })
    );
    this.setState({ countryOptions: countryList().getData() });
  }

  onSubmit = (e) => {
    this.setState({
      loading: true,
      error: false,
      success: false,
    });

    e.preventDefault();
    const url =
      "https://gmail.us11.list-manage.com/subscribe/post?u=c2288d4bacc129c1c97717956&amp;id=0349b8a037&amp;f_id=00ccd4e3f0";
    jsonp(
      `${url}&FNAME=${this.state.axName}&LNAME=${this.state.axLastName}&BNAME=${this.state.axBusinessName}&MMERGE10=${this.state.axRole}&MMERGE11=${this.state.axCountry}&EMAIL=${this.state.axEmail}`,
      { param: "c" },
      (_, data) => {
        const { result } = data;

        if (result === "error") {
          this.setState({
            loading: false,
            error: true,
          });
        } else {
          this.setState({
            loading: false,
            success: true,
          });
        }
      }
    );
  };

  render() {
    const { t } = this.props;

    const isMobile = this.state.width <= 1300;

    return (
      <div className="container" style={{ maxWidth: "1300px" }}>
        <div className="row">
          <button onClick={this.props.handleShow} className="leave-tour-btn">
            <IoIosArrowRoundBack size={25} />
            {t("tour.leaveTour")}
          </button>
        </div>
        <div className="row">
          <div
            className="App"
            style={{
              width: "100vw",
              height: "100vh",
            }}
          >
            {this.state.success ? (
              <>
                {!this.state.loadedIFrame ? (
                  <Bars
                    height="40"
                    width="40"
                    color="#2B80F2"
                    ariaLabel="bars-loading"
                    wrapperStyle={{ justifyContent: "center" }}
                    wrapperClass="mt--20"
                    visible={true}
                  />
                ) : (
                  <></>
                )}
                <iframe
                  title="ProductTour"
                  onLoad={() => this.setState({ loadedIFrame: true })}
                  style={{
                    border: "0",
                  }}
                  width="100%"
                  height="100%"
                  allowfullscreen
                  src="https://embed.figma.com/proto/uV7dkmi58R7AQInb1fIxiV/Bloomide_app?page-id=0%3A1&node-id=32-940&p=f&viewport=3394%2C731%2C0.76&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=32%3A940&embed-host=share&hide-ui=1&disable-default-keyboard-nav=1&hotspot-hints=false&footer=false"
                />
              </>
            ) : (
              <div
                className="col-12 col-sm-11 col-md-11 col-lg-6 contact-card mt--20"
                style={{ display: "inline-block" }}
              >
                <h4>{t("tour.formTitle")}</h4>
                <p className="mt--20 mb--40">{t("tour.formDesc")}</p>
                <form
                  id="contact"
                  className="contact-form form-tour"
                  onSubmit={this.onSubmit}
                >
                  <div className="container">
                    {this.state.error && (
                      <div className="subscribe-error row mb--30 col-12">
                        {t("contact.form.errorMssg")}
                      </div>
                    )}
                    <div className="row">
                      <div className="col-6">
                        <input
                          type="text"
                          name="name"
                          id="item01"
                          className="col-12"
                          value={this.state.axName}
                          required
                          onChange={(e) => {
                            this.setState({ axName: e.target.value });
                          }}
                          placeholder={t("contact.form.name")}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          name="lastName"
                          id="item02"
                          className="col-12"
                          value={this.state.axLastName}
                          required
                          onChange={(e) => {
                            this.setState({ axLastName: e.target.value });
                          }}
                          placeholder={t("contact.form.lastName")}
                        />
                      </div>
                    </div>
                    <div className="row mt--20">
                      <div className="col-12">
                        <input
                          type="text"
                          name="email"
                          id="item04"
                          className="col-12"
                          value={this.state.axEmail}
                          required
                          pattern=".*\S+.*"
                          onChange={(e) => {
                            this.setState({ axEmail: e.target.value });
                          }}
                          placeholder={t("contact.form.email")}
                        />
                      </div>
                    </div>

                    <div className="row mt--20">
                      <div className="col-6">
                        <input
                          type="text"
                          name="company"
                          id="item01"
                          className="col-12"
                          value={this.state.axBusinessName}
                          required
                          onChange={(e) => {
                            this.setState({ axBusinessName: e.target.value });
                          }}
                          placeholder={t("contact.form.company")}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          type="text"
                          name="role"
                          id="item02"
                          className="col-12"
                          value={this.state.axRole}
                          required
                          onChange={(e) => {
                            this.setState({ axRole: e.target.value });
                          }}
                          placeholder={t("contact.form.role")}
                        />
                      </div>
                    </div>

                    <div className="row mt--20">
                      <div className="col-12">
                        <Select
                          name="country"
                          id="item06"
                          className="col-12 country-selector"
                          required
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          onChange={(e) => {
                            this.setState({ axCountry: e.value });
                          }}
                          options={this.state.countryOptions}
                          placeholder={t("contact.form.country")}
                        />
                      </div>
                    </div>

                    {isMobile && (
                      <div className="row mt--30">
                        <div
                          className="col-12 alert alert-warning"
                          role="alert"
                        >
                          <div>
                            <Trans
                              i18nKey="tour.mobileWarningTitle"
                              components={[<strong />]}
                            />
                          </div>
                          <div className="mt--10">
                            <Trans
                              i18nKey="tour.mobileWarningDesc"
                              components={[<NavLink to="/contact" />]}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className="row mt--30"
                      style={{ justifyContent: "center" }}
                    >
                      <div className="col-7">
                        <button
                          className="submit-btn col-12"
                          type="submit"
                          value="submit"
                          name="submit"
                          id="mc-embedded-subscribe"
                          disabled={this.state.loading}
                          style={
                            this.state.loading
                              ? { cursor: "not-allowed", opacity: "0.6" }
                              : {}
                          }
                        >
                          {t("contact.form.start")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EmbeddedPrototype);
