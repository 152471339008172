import React, { Component } from "react";
import "./../css/component/how-it-works.css";
import Steps from "../component/steps";
import { Trans, withTranslation } from "react-i18next";

class HowItWorks extends Component {
  render() {
    return (
      <section
        id="how-it-works"
        className={`${
          this.props.howItWorksStyle ? this.props.howItWorksStyle : ""
        }`}
      >
        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="banner-text">
                  <h4 className="how-it-works-title">
                    <Trans
                      i18nKey={"howItWorks.subtitle"}
                      components={[<span />]}
                    />
                  </h4>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="pricing-tab-content-body">
                <Steps />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(HowItWorks);
