import React, { Component } from "react";
import "./../css/banner.css";
import { Trans, withTranslation } from "react-i18next";
import i18n from "../i18n";
import { NavLink } from "react-router-dom";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import ScrollAnimation from "react-animate-on-scroll";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();
  }

  render() {
    const currentLng = i18n.language.includes("es") ? "ES" : "US";
    const { t } = this.props;

    const handlePlayerEvent = (e) => {
      if (e === "frame") {
        if (this.playerRef.current && this.playerRef.current.state.instance) {
          const { currentFrame, totalFrames } =
            this.playerRef.current.state.instance;
          if (currentFrame >= totalFrames - 4) {
            this.playerRef.current.pause();
          }
        }
      }
    };

    return (
      <section
        id="banner"
        className={`${this.props.bannerStyle ? this.props.bannerStyle : ""}`}
      >
        <Player
          autoplay
          speed={0.5}
          loop={true}
          src="/lottiefiles/pulse.json"
          className="pulse-back"
        >
          <Controls visible={false} />
        </Player>

        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div className="col-12 banner-text">
                <h1 className={"title-" + currentLng}>
                  <Trans
                    i18nKey="banner.title"
                    components={[
                      <ScrollAnimation
                        animateIn="fadeIn"
                        delay={200}
                        offset={-600}
                        duration={1.5}
                        animateOnce={true}
                      />,
                    ]}
                  />
                </h1>
              </div>
            </div>

            <div className="row description-row">
              <div className="col-12 col-lg-5 banner-text">
                <p>{t("banner.subtitle")}</p>

                <NavLink to={t("/tour")}>
                  <button className="banner-btn-tour">{t("banner.button")}</button>
                </NavLink>
                <p className="tour-desc">{t("banner.tourSubText")}</p>
              </div>
              <div className="col-12 col-lg-7 justify-content-center">
                <Player
                  autoplay
                  loop={false}
                  src="/lottiefiles/data.json"
                  style={{ width: "100%" }}
                  ref={this.playerRef}
                  onEvent={handlePlayerEvent}
                >
                  <Controls visible={false} />
                </Player>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Banner);
