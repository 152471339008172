import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withTranslation } from "react-i18next";
import "./../css/component/custom-modal.css";

class CustomModal extends Component {
  render() {
    const { t } = this.props;

    return (
      <Modal show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Body>{t("tour.confirmationTxt")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.props.handleClose}
            className="modal-cancel-btn"
          >
            {t("tour.cancelBtn")}
          </Button>
          <Button className="modal-leave-btn" onClick={this.props.handleSubmit}>
            {t("tour.leaveBtn")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(CustomModal);
