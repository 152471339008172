import React, { Component, Fragment, Suspense } from "react";
import Header from "../sections/header";
import ContactSection from "../sections/contact-section";
import Footer from "../sections/footer-section";
import { initAnalytics } from "../GAUtils";
import { Bars } from "react-loader-spinner";

class Contact extends Component {
  async componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tourParam = params.get("tour");

    const event = tourParam
      ? {
          name: "conclude_tour",
          params: {
            category: "User",
            label: "tour",
          },
        }
      : null;

    initAnalytics("/contact", event);
  }

  render() {
    return (
      <Fragment>
        <Suspense
          fallback={
            <Bars
              height="40"
              width="40"
              color="#2B80F2"
              ariaLabel="bars-loading"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClass="mt--20"
              visible={true}
            />
          }
        >
          <div className="App">
            <Header headerStyle={"header header-style-1"}></Header>
            <ContactSection></ContactSection>
            <Footer
              footerStyle={"footer-dark"}
              marginStyle={"pt--100 pt_sm--120 pb--20 pb_lg--20"}
            ></Footer>
          </div>
        </Suspense>
      </Fragment>
    );
  }
}

export default Contact;
