import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import { Link, NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import "./../css/header.css";
import i18n from "../i18n";
import { CircleFlag } from "react-circle-flags";

class Header extends Component {
  constructor() {
    super();
    const currentLng = i18n.language.includes("es") ? "es" : "us";

    this.state = {
      lang: {
        value: i18n.language,
        label: <CircleFlag countryCode={currentLng} height="20" />,
      },
    };

    this.updateLanguage = this.updateLanguage.bind(this);
  }

  updateLanguage(event) {
    const flag = event.value === "es" ? "es" : "us";
    this.setState({
      lang: {
        value: event.value,
        label: <CircleFlag countryCode={flag} height="20" />,
      },
    });
    i18n.changeLanguage(event.value);
  }

  menuTrigger() {
    document.querySelector("body").classList.add("mobile-menu-open");
  }
  closeMenuTrigger() {
    document.querySelector("body").classList.remove("mobile-menu-open");
  }
  render() {
    const languageOptions = [
      { value: "es", label: <CircleFlag countryCode="es" height="20" /> },
      { value: "en", label: <CircleFlag countryCode="us" height="20" /> },
    ];

    var elements = document.querySelectorAll(".has-childmenu > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { headerStyle } = this.props;

    const { t } = this.props;

    return (
      <header className={`${headerStyle}`}>
        <div id="mainNav" className="main-menu-area header--fixed">
          <div className="container">
            <div className="row m-0 align-items-center">
              <div className="col-lg-3 p-0 d-flex justify-content-between align-items-center">
                <div className="logo">
                  <Link className="navbar-brand navbar-brand1" to="/#">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                  <Link className="navbar-brand navbar-brand2" to="/#">
                    <img src="assets/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <div className="menu-bar d-lg-none" onClick={this.menuTrigger}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="op-mobile-menu col-lg-9 p-0 d-lg-flex align-items-center justify-content-end">
                <div className="m-menu-header d-flex justify-content-end d-lg-none">
                  <span
                    className="close-button"
                    onClick={this.closeMenuTrigger}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </div>
                <Scrollspy
                  className="custom-border-top nav-menu d-lg-flex flex-wrap list-unstyled  mb-0 justify-content-center"
                  offset={-200}
                >
                  <li className="language-div">
                    <Select
                      className="language-picker"
                      options={languageOptions}
                      isSearchable={false}
                      value={this.state.lang}
                      onChange={this.updateLanguage}
                      menuPlacement="bottom"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          border: 0,
                          boxShadow: "none",
                          zIndex: 9999,
                        }),
                      }}
                    />
                  </li>
                  <li className="has-childmenu">
                    <NavLink to={t("header.useCases.link")}>
                      {t("header.useCases.title")}
                      <IoIosArrowDown style={{ marginLeft: "4px" }} />
                    </NavLink>
                    <ul className="submenu">
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.invest.link")}
                        >
                          <span>{t("header.useCases.invest.title")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.hr.link")}
                        >
                          <span>{t("header.useCases.hr.title")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.business.link")}
                        >
                          <span>{t("header.useCases.business.title")}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={this.closeMenuTrigger}
                          to={t("header.useCases.strategies.link")}
                        >
                          <span>{t("header.useCases.strategies.title")}</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="faq-head">
                    <NavLink onClick={this.closeMenuTrigger} to="/faq">
                      <span>{t("header.faq")}</span>
                    </NavLink>
                  </li>
                  <li className="get-in-touch-header">
                    <NavLink to={t("header.getInTouchPage")}>
                      <button>
                        {" "}
                        <span className="header-btn-text">{t("header.getInTouch")}</span>
                      </button>
                    </NavLink>
                  </li>
                </Scrollspy>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default withTranslation()(Header);
