import React, { Component, Fragment, Suspense } from "react";
import Header from "../sections/header";
import FaqSection from "../sections/faq-section";
import Footer from "../sections/footer-section";
import { Bars } from "react-loader-spinner";

class Faq extends Component {
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document
          .querySelector(".header--fixed")
          .classList.add(["sticky"], ["animated"], ["slideInDown"]);
      } else {
        document
          .querySelector(".header--fixed")
          .classList.remove(["sticky"], ["animated"], ["slideInDown"]);
      }
    });
    return (
      <Fragment>
        <Suspense
          fallback={
            <Bars
              height="40"
              width="40"
              color="#2B80F2"
              ariaLabel="bars-loading"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClass="mt--20"
              visible={true}
            />
          }
        >
          <div className="App">
            <Header headerStyle={"header header-style-1"}></Header>
            <FaqSection
              faqStyle={"faq-section pt--135 pb--60 pt_lg--170 pb_lg--80"}
            ></FaqSection>
            <Footer
              footerStyle={"footer-dark"}
              marginStyle={"pt--100 pt_sm--120 pb--20 pb_lg--20"}
            ></Footer>
          </div>
        </Suspense>
      </Fragment>
    );
  }
}

export default Faq;
