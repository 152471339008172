import React, { Component } from "react";
import UseCaseTextSection from "../component/use-case-text-section";
import { initAnalytics } from "../GAUtils";
import { Trans, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "../css/investment-use-case-section.css";

class InvestmentUseCaseSection extends Component {
  async componentDidMount() {
    initAnalytics("/use-cases");
  }

  render() {
    const { t } = this.props;

    const renderWithColors = (text) => {
      return text.split(/(<colored>.*?<\/colored>)/g).map((part, i) => {
        if (part.startsWith("<colored>") && part.endsWith("</colored>")) {
          return (
            <span key={i} style={{ color: "#30BF2C" }}>
              {part.slice(9, -10)}
            </span>
          );
        }
        return part;
      });
    };

    return (
      <section
        id="investment-use-cases"
        className={`${this.props.caseStyle ? this.props.caseStyle : ""}`}
      >
        <h1 className="use-case-title">
          {renderWithColors(t("newUseCase.common"))}
        </h1>
        <UseCaseTextSection></UseCaseTextSection>

        <div className="container mt--120">
          <div className="contact-box-use-case">
            <div className="container">
              <div className="row justify-content-center text-center join-text pt--30 pb--15">
                <span className="contact-description">
                  <Trans i18nKey="contactFooter.descriptionReduced" />
                </span>
              </div>
              <div className="row justify-content-center btn-container use-case-contact mt--15 mb--30">
                <NavLink to={t("contactFooter.getInTouchPage")}>
                  <button className="contact-btn-use-case">
                    {t("contactFooter.getInTouch")}
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(InvestmentUseCaseSection);
