import React, { Component } from "react";
import "./../css/component/use-cases.css";
import UseCaseItem from "./use-case-item";

class UseCase extends Component {
  render() {
    return (
      <>
        <div className="row justify-content-center justify-content-lg-start pt--50">
          <UseCaseItem
            id={"investors"}
            cardSizeClass={"col-8 col-sm-8 col-md-6 col-lg-3"}
            linkPath={"header.useCases.invest.link"}
          />

          <UseCaseItem
            id={"hr"}
            cardSizeClass={"col-8 col-sm-8 col-md-6 col-lg-3"}
            linkPath={"header.useCases.hr.link"}
          />

          <UseCaseItem
            id={"business"}
            cardSizeClass={"col-8 col-sm-8 col-md-6 col-lg-3"}
            linkPath={"header.useCases.business.link"}
          />

          <UseCaseItem
            id={"strategies"}
            cardSizeClass={"col-8 col-sm-8 col-md-6 col-lg-3"}
            linkPath={"header.useCases.strategies.link"}
          />
        </div>
      </>
    );
  }
}

export default UseCase;
