import React, { Component } from "react";
import "./../css/use-case.css";
import { Trans, withTranslation } from "react-i18next";
import UseCases from "../component/use-cases";

class UseCaseSection extends Component {
  render() {
    return (
      <section
        id="use-cases"
        className={`${
          this.props.useCasesStyle ? this.props.useCasesStyle : ""
        }`}
      >
        <div className="banner-content d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="banner-text">
                  <h4 className="use-cases-title">
                    <Trans
                      i18nKey={"useCases.subtitle"}
                      components={[<span />]}
                    />
                  </h4>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="pricing-tab-content-body">
                <UseCases></UseCases>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(UseCaseSection);
