import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import "./../css/component/use-case-text-section.css";
import { withTranslation } from "react-i18next";

const UseCaseTextSection = ({ t }) => {
  const location = useLocation();

  const [selectedSection, setSelectedSection] = useState(() => {
    const hash = location.hash.replace("#", "");
    return hash || "investors";
  });

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    setSelectedSection(hash || "investors");
  }, [location]);

  const handleSectionClick = useCallback((section, event) => {
    event.preventDefault();
    window.location.hash = section;
  }, []);

  const createSection = useCallback(
    (key) => {
      const challenge = `newUseCase.${key}.challenge`;
      const contribution = `newUseCase.${key}.contribution`;
      const benefits = `newUseCase.${key}.benefits`;

      return (
        <>
          <div className="text">
            <h2>{t(`${challenge}.title`)}</h2>
            <p>{t(`${challenge}.content`)}</p>
            <h2>{t(`${contribution}.title`)}</h2>
            <p>{t(`${contribution}.content`)}</p>
            <h2>{t(`${benefits}.title`)}</h2>
            <ul className="checklist">
              {[...Array(5)].map((_, i) => (
                <li key={i}>{t(`${benefits}.benefit${i + 1}`)}</li>
              ))}
            </ul>
          </div>
          <img src={t(`newUseCase.${key}.img`)} alt={t(`${challenge}.title`)} />
        </>
      );
    },
    [t]
  );

  const sections = {
    investors: createSection("investors"),
    HrConsultantsAndRecruiters: createSection("HrConsultantsAndRecruiters"),
    businessConsultants: createSection("businessConsultants"),
    managementTeams: createSection("managementTeams"),
  };

  return (
    <div className="square-container">
      <div className="tab-header">
        {Object.keys(sections).map((section) => (
          <button
            key={section}
            className={`tab-button ${
              selectedSection === section ? "active" : ""
            }`}
            onClick={(event) => handleSectionClick(section, event)}
          >
            {t(`newUseCase.${section}.title`)}
          </button>
        ))}
      </div>
      <div className="tab-content">{sections[selectedSection]}</div>
    </div>
  );
};

export default withTranslation()(UseCaseTextSection);
