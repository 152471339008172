import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import "./../css/answer-questions-section.css";
import { NavLink } from "react-router-dom";
import { Particles } from "@blackbox-vision/react-particles";

class AnswerQuestionsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ width: window.innerWidth })
    );
    return () => {
      window.removeEventListener("resize", () =>
        this.setState({ width: window.innerWidth })
      );
    };
  }

  render() {
    const { t } = this.props;

    return (
      <section
        id="answer-questions"
        className={
          "d-flex justify-content-center align-items-center position-relative " +
          `${this.props.testimonialStyle ? this.props.testimonialStyle : ""}`
        }
      >
        <Particles
          id="simple"
          style={{ position: "absolute" }}
          params={{
            particles: {
              color: { value: "#ffffff" },
              links: {
                color: "#ffffff",
                distance: 180,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              number: {
                value:
                  this.state.width > 760
                    ? 140
                    : this.state.width > 400
                    ? 70
                    : 30,
              },
              size: {
                value: 3,
              },
            },
            interactivity: {
              events: {
                onhover: {
                  enable: false,
                },
              },
            },
          }}
        />
        <div className="container">
          <div className="col-12 contact-box">
            <div className="row justify-content-center answer-title">
              <h4>
                <Trans
                  i18nKey={"contactFooter.title"}
                  components={[<span />]}
                />
              </h4>
            </div>

            <div className="row justify-content-center answer-subtitle">
              <span>{t("contactFooter.description")}</span>
            </div>

            <div className="row justify-content-center btn-container">
              <NavLink to={t("contactFooter.getInTouchPage")}>
                <button>{t("contactFooter.getInTouch")}</button>
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(AnswerQuestionsSection);
