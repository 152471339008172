import React, { Component, Fragment, Suspense } from "react";
import "./../css/app.css";
import Header from "./../sections/header";
import Banner from "./../sections/banner";
import UseCaseSection from "../sections/use-case-section";
import HowItWorks from "../sections/how-it-works-section";
import AnswerQuestionsSection from "../sections/answer-questions-section";
import Footer from "../sections/footer-section";
import { initAnalytics } from "../GAUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Bars } from "react-loader-spinner";

class App extends Component {
  async componentDidMount() {
    initAnalytics("/");
  }

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        if (document.querySelector(".header--fixed")) {
          document
            .querySelector(".header--fixed")
            .classList.add(["sticky"], ["animated"], ["slideInDown"]);
        }
      } else {
        if (document.querySelector(".header--fixed")) {
          document
            .querySelector(".header--fixed")
            .classList.remove(["sticky"], ["animated"], ["slideInDown"]);
        }
      }
    });
    return (
      <Fragment>
        <Suspense
          fallback={
            <Bars
              height="40"
              width="40"
              color="#2B80F2"
              ariaLabel="bars-loading"
              wrapperStyle={{ justifyContent: "center" }}
              wrapperClass="mt--20"
              visible={true}
            />
          }
        >
          <div className="App">
            <Header headerStyle={"header header-style-1"}></Header>
            <Banner bannerStyle={"banner-section"}></Banner>
            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <UseCaseSection
                useCasesStyle={
                  "use-case-section pt--60 pb--60 pt_lg--65 pb_lg--80"
                }
              ></UseCaseSection>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
              <HowItWorks
                howItWorksStyle={
                  "how-it-works-section pt--60 pb--60 pt_lg--65 pb_lg--80"
                }
              ></HowItWorks>
            </ScrollAnimation>

            <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
              <AnswerQuestionsSection
                testimonialStyle={
                  "answer-questions-section overflow-hidden pb--50 pt--50"
                }
              ></AnswerQuestionsSection>
            </ScrollAnimation>
            <Footer
              footerStyle={"footer"}
              marginStyle={"pb--20 pb_lg--20"}
            ></Footer>
          </div>
        </Suspense>
      </Fragment>
    );
  }
}

export default App;
