import React, { Component } from "react";
import "./../css/footer.css";

class Footer extends Component {
  render() {
    return (
      <footer
        className={`${this.props.footerStyle ? this.props.footerStyle : ""}`}
      >
        <div className={"footer-top " + this.props.marginStyle}>
          <div className="container">
            <div className="row pre-footer" />
            <div className="row">
              <div className="col-6 col-sm-6 col-md-4 col-lg-3 footer-logo">
                <img
                  src={
                    this.props.footerStyle &&
                    this.props.footerStyle === "footer-dark"
                      ? "assets/images/logo.png"
                      : "assets/images/logo_white.png"
                  }
                  alt="logo"
                />
              </div>

              <div className="col-6 col-md-4 col-lg-6 contact-cta">
                <div>
                  <a
                    href="mailto:info@bloomide.com"
                    target="_top"
                    className="mail-cta"
                  >
                    info@bloomide.com
                  </a>

                  <a
                    href="https://www.linkedin.com/company/bloomide"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="linkdn-ref"
                  >
                    <img
                      src={
                        this.props.footerStyle &&
                        this.props.footerStyle === "footer-dark"
                          ? "assets/images/LI_dark.png"
                          : "assets/images/LI.png"
                      }
                      style={{ maxWidth: "16px" }}
                      alt="LinkedIn"
                    />
                  </a>
                </div>
              </div>

              <div className="col-6 col-sm-6 col-md-4 col-lg-3 pl-lg-3 copyright-footer">
                <p>&copy; Copyrights {new Date().getFullYear()} Bloomide</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
