import ReactGA from "react-ga4";

export function initAnalytics(pageView = null, event = null) {
  if (!isLocalhost()) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    if (pageView) {
      ReactGA.send({ hitType: "pageview", page: pageView });
    } 
    if (event) {
      ReactGA.event(event.name, event.params);
    }
  }
}

function isLocalhost() {
  return Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );
}
