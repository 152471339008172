import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";

class UseCaseItem extends Component {
  render() {
    const { id, cardSizeClass, linkPath, t } = this.props;

    const transKey = "useCases." + id;

    return (
      <div className={cardSizeClass + " use-case-card"}>
        <NavLink to={t(linkPath)}>
          <div className={"use-case-item use-case-" + id + " mb_lg--0"}>
            <div className="use-case-wrapper">
              <div>
                <img
                  className="img-inactive"
                  src={t(transKey + ".imgPath")}
                  alt="Use Case"
                />
                <img
                  className="img-active"
                  src={t(transKey + ".imgPathWhite")}
                  alt="Use Case"
                />
              </div>
              <div>
                <p className="use-case-head">{t(transKey + ".title")}</p>
                <p className="use-case-subtitle">{t(transKey + ".subtitle")}</p>
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }
}

export default withTranslation()(UseCaseItem);
